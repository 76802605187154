<template>
  <div class="container-fluid position-relative image-gallery">
    <canvas ref="slideshow"></canvas>
    <div role="button" @click.stop="playing=false;nextImage(1)" class="btn-gallery btn-next"><i class="bi bi-chevron-right"></i></div>
    <div role="button" @click.stop="playing=false;nextImage(-1)" class="btn-gallery btn-prev"><i class="bi bi-chevron-left"></i></div>
    <div role="button" @click.stop="togglePlayer" class="btn-gallery btn-play"><i class="bi" :class="{'bi-play':!playing,'bi-pause':playing}"></i></div>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, onBeforeUnmount, nextTick} from "vue"
import { gsap } from "gsap"

const props = defineProps({
  autoPlay: {
    type: Boolean,
    default: false
  },
  images: {
    type: Array,
     default: () => []
  }
})

const currentIndex = ref(0)
const playing = ref(props.autoPlay)
const loaded = ref(false)
const slideshow = ref(null)
let timer = null
let canvasImages = [];
const transTime = 300;
let timeline = gsap.timeline()

onMounted(() => {
  preloadImages()
})

onBeforeUnmount(() => {
  if (timer) {
    clearTimeout(timer)
    timer = null
  }
  loaded.value = false
  timeline.kill()
  timeline = null
})

async function preloadImages() {
  await nextTick()
  const canvas = slideshow.value
  if (!canvas) return;
  canvas.width = 640;
  canvas.height = 640;
  if (!canvas) return;
  const promises = []
  canvasImages = props.images.map(image => {
    const img = new Image()
    img.src = image
    promises.push(new Promise(resolve => img.onload = resolve))
    return {
      img,
      x:0,
      y:0,
      alpha: 0,
      scale: 1,
    }
  })
  await Promise.all(promises)
  window.requestAnimationFrame(run)
  loaded.value = true
  nextImage(0) // draw first image upload loaded
  if (playing.value) {
    // start player if playing = true
    timer = setTimeout(nextImage, 3000)
  }
}

function run() {
  if (loaded.value) window.requestAnimationFrame(run)
  if (!slideshow.value) return;
  const canvas = slideshow.value
  const ctx = canvas.getContext("2d")
  ctx.globalAlpha = 1;
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  canvasImages.forEach(image => {
    if (image.alpha > 0) {
      ctx.globalAlpha = image.alpha;
      ctx.drawImage(image.img, image.x, image.y, canvas.width, canvas.height)
    }
  })
}

function togglePlayer() {
  if (timer) {
    clearInterval(timer)
    timer = null
  }
  playing.value = !playing.value
  if (playing.value) timer = setTimeout(nextImage, 3000)
}

function nextImage(dir=1) {
  if (timer) {
    clearInterval(timer)
    timer = null
  }

  currentIndex.value = (currentIndex.value + dir + props.images.length) % props.images.length;

  if (dir) { // if dir is change - set incoming slide to off screen
    canvasImages[currentIndex.value].x = dir * slideshow.value.width
    canvasImages[currentIndex.value].alpha = 0
  }
  timeline.to(canvasImages[currentIndex.value], {alpha:1, x:0, duration: transTime/1000})

  if (dir) { // if dir, set last value to outgoing off screen
    timeline.to(canvasImages[(currentIndex.value - dir + props.images.length) % props.images.length], {alpha:0, x: -dir * slideshow.value.width, duration: transTime/1000}, `=-${transTime/1000}`)
  }
  if (!playing.value) return;
  timer = setTimeout(nextImage, 3000)
}
</script>

<style lang="scss" scoped>
  .image-gallery {
    height:0;
    padding-top: 100%;
    canvas {
      position: absolute;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
    }
    &:hover{
      .btn-gallery {opacity:1;}
    }
  }
  $size: 40px;
  .btn-gallery {
    opacity: 0;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: calc(50% - $size / 2);
    width: $size;
    height: $size;
    border-radius: 50%;
    //border: 1px solid #000;
    background-color: #fff;
    //color: blue;
    font-size: $size/1.5;
    line-height: $size;
    text-align: center;
    transition: all 0.15s ease-in-out;
    &:hover {
      background-color: #000;
      color: #fff;
    }
    &.btn-next{
      right:  $size/3;
    }
    &.btn-prev{
      left: $size/3;
    }
    &.btn-play {
      top: unset;
      bottom: $size/3;
      left: calc(50% - $size/2);
    }
  }
</style>
