<template>
  <div class="card">
    <div class="d-flex">
      <div class="p-3 border-end" :class="{'bg-success-subtle':checked}" role="button" @click.stop.prevent="selectItem">
        <i class="bi" :class="{'bi-check-circle':checked, 'bi-circle':!checked}"></i>
      </div>
      <a :href="`https://www.ebay.com/itm/${item.remote_id}`" target="_blank">
        <lazy-image :image-src="`https://i.ebayimg.com/images/g/${item.photo_url.match(/z\/([a-zA-Z0-9-~_]+)\/\$/)?.[1]}/s-l140.jpg`"
                    style="width:140px; height: 140px;"
                    class="card-img me-3"
                    :title="item.title"/>
      </a>
      <div class="card-body position-relative">
        <div class="fw-bold">
          <span v-if="item.is_sold" class="badge bg-danger text-white me-2">SOLD</span>
          <a :href="`https://www.ebay.com/itm/${item.remote_id}`" target="_blank">{{ item.title }}</a>
        </div>
        <div class="lead">${{ item.sale_price}}</div>
        <div v-if="false" v-html="item.description"></div>
        <div class="text-muted"><small>{{ item.SKU }}</small></div>
        <div class="position-absolute" style="bottom:0; right: 0;">
          <update-inventory-item-button :item-id="item.remote_id" />
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import UpdateInventoryItemButton from "@/components/UpdateInventoryItemButton.vue";
import LazyImage from "@/components/Utils/LazyImage.vue";
const props = defineProps({
  item: Object,
  selected: {
    type: Boolean,
    default: false,
  }
})
const emit = defineEmits(['onSelected'])
const checked = ref(props.selected)

function selectItem() {
  emit('onSelected', !checked.value);
}

watch(() => props.selected, () => {
  checked.value = props.selected;
})


</script>

<style lang="scss" scoped>

</style>
