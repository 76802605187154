<template>
  <div>
    <button type="button" class="btn btn-primary d-none" @click="getSellerItems()">Fetch eBay Data</button>
    <static-alert :message="message" @on-hide="message=''" />
    <transition mode="out-in">
      <loading-spinner v-if="loading" />
      <div v-else-if="itemsInput.length > 0">
        <div class="lead">{{ fetchDetails?.PaginationResult?.TotalNumberOfEntries || 0 }} Active Items</div>
        <div class="lead">{{ items.length }} Items Not Imported</div>
        <div>
          <select v-model="page" @change="loadMore(0)" class="form-select">
            <option v-for="a in Math.floor(items.length/perPage)" :key="a" :value="a">{{a}}</option>
          </select>
          <span v-if="itemsInput.length">
            <button type="button" @click="saveItems" class="btn btn-primary ms-3">
              <i class="bi-save"></i>
            </button>
          </span>
          <span v-if="items.length">
            <button type="button" @click="processAllItems" class="btn btn-primary ms-3">
              <i class="bi-check-circle"></i> Run Update
            </button>
          </span>
        </div>

        <paginator-bar
            v-if="items.length > perPage"
            :total-items="items.length"
            :per-page="perPage"
            :page="page"
            @next-page="loadMore" />

        <div v-for="item in filtered"
            :key="item.ItemID"
            @click.stop="selectItem(item)"
            :class="{'bg-success-subtle': selectedItem === item}"
        >
          <div v-if="item.Description">
            <ebay-item-list-detail :item="item" />
          </div>

          <div v-if="item.ItemID">
            {{ item.ItemID }} <update-inventory-item-button :item-id="item.ItemID" />
          </div>

          <div v-else class="border-bottom" role="button">
            <small>{{ item.title }} <strong>{{ item.condition_name }}</strong></small>
          </div>

        </div>

        <div>
          <button type="button" class="btn btn-link" @click="itemsInput=[]">Close/Done</button>
        </div>

      </div>
      <div v-else>
        <button type="button" class="btn btn-link" @click="getSellerItems">
          <i class="bi-arrow-clockwise me-2"></i>
          Fetch/Update from eBay
        </button>
      </div>
    </transition>
    <app-modal :modal-in="selectedItem != null" @close="selectedItem=null">
      <template #header>Item {{ selectedItem.ItemID }} Details</template>
      <div class="container" style="max-width:300px;">
      <ebay-item if="item.ItemID" :line="{legacyItemId:selectedItem.ItemID || selectedItem.remote_id}" />
      </div>
    </app-modal>
  </div>
</template>

<script setup>

import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue"
import {ref, nextTick, computed} from 'vue'
import { useStore } from 'vuex'
import EbayItem from "@/components/ebay/EbayItem.vue";
import EbayItemListDetail from "@/components/ebay/EbayItemListDetail.vue";
import PaginatorBar from "@/components/Utils/PaginatorBar.vue";
import StaticAlert from "@/components/Utils/StaticAlert.vue";
import AppModal from "@/components/Utils/AppModal.vue";
import UpdateInventoryItemButton from "@/components/UpdateInventoryItemButton.vue";

const loading = ref(false)
const message = ref('')
const items = ref(null)
const fetchDetails = ref(null)
const selectedItem = ref(null)
const page = ref(1)
const perPage = ref(30)
const granularity = ref(null) // 'Coarse' || 'Medium' || 'Fine' || null
const store = useStore()
const inventoryIds = computed(() => store.state.eshop.inventory.map(item => item.remote_id))
const filtered = computed(() => {
  if (!items.value) return []
  const start = (page.value - 1) * perPage.value
  const end = page.value * perPage.value
  return items.value.slice()
      .sort((a, b) => new Date(b.ListingDetails.StartTime) - new Date(a.ListingDetails.StartTime))
      .slice(start, end)
})

const itemsInput = ref([]);

function setInput(item) {
  return {
    ItemID: item.ItemID,
    title: item?.Title,
    create_date: item?.ItemDetails?.StartTime,
    description: item?.Description,
    photo_url: item?.PictureDetails?.PictureURL[0],
    photo_gallery: item?.PictureDetails?.GalleryType === 'Gallery' ? item?.PictureDetails?.PictureURL : [],
    remote_store: 'EBAY',
    remote_id: item?.ItemID,
    SKU: item?.SKU,
    price: item?.StartPrice?.value,
    initial_quantity: parseInt(item?.Quantity),
    available_quantity: parseInt(item?.Quantity),
    category_id: parseInt(item?.PrimaryCategory?.CategoryID || 0),
    category_name: item?.PrimaryCategory?.CategoryName,
    condition_id: parseInt(item?.ConditionID || 0),
    condition_name: item?.ConditionDisplayName,
    condition_description: item?.ConditionDescription,
    weight_lbs: parseInt(item?.ShippingPackageDetails?.WeightMajor?.value || 0),
    weight_ozs: parseInt(item?.ShippingPackageDetails?.WeightMinor?.value || 0),
    package_width: parseInt(item?.ShippingPackageDetails?.PackageWidth?.value || 0),
    package_length: parseInt(item?.ShippingPackageDetails?.PackageLength?.value || 0),
    package_depth: parseInt(item?.ShippingPackageDetails?.PackageDepth?.value || 0),
  }
}

function selectItem(item) {
  if (item === selectedItem.value) {
    selectedItem.value = null
    return
  }
  selectedItem.value = item
}

async function loadMore(dir) {
  loading.value = true
  page.value += dir
  if (!granularity.value) {
    await nextTick()
    loading.value = false
  } else {
    getSellerItems()
  }
}

async function processAllItems() {
  for (const item of items.value
      .slice()
      .sort((a, b) => new Date(a.ListingDetails?.StartTime) - new Date(b.ListingDetails?.StartTime))
      ) {
    const data = await store.dispatch('eshop/updateInventoryItem', {id: item.ItemID})
    message.value = data?.result?.error || `Successfully updated item ${data.item.title}`
    await nextTick() //new Promise(resolve => setTimeout(resolve, 100))
  }
}

function getSellerItems() {
  loading.value = true
  store.dispatch('eshop/getSellerItems', {granularity: granularity.value, limit: perPage.value, page: page.value })
      .then(list => {
        items.value = (list.ItemArray.Item || []).filter(item => inventoryIds.value.indexOf(item.ItemID) === -1)
        itemsInput.value = items.value.map(setInput)
        fetchDetails.value = { ...list, ItemArray: [], HasMoreItems: granularity.value ? list.HasMoreItems : true }
        loading.value = false
      })
      .catch(error => {
        message.value = error.response?.data || error.message || error
        loading.value = false
        console.log('getSellerItems error', error)
      })
}

async function stepMessages(messages) {
  for (const m of messages) {
    message.value = m
    await new Promise(resolve => setTimeout(resolve, 10))
    message.value = null
    await nextTick()
  }
}

function saveItems() {
  store.dispatch('eshop/saveInventoryItems', { items: itemsInput.value })
    .then(inserts => inserts.map(item => item.id ? `saved ${item.id}` : `error: ${item.error}`)
    )
    .then(stepMessages)
    .catch(error => {
      message.value = error.response?.data || error.message || error
    })
}

// function saveItem(item) {
//   store.dispatch('eshop/saveInventoryItem', { item })
//     .then(id => {
//       message.value = `saved ${id}`;
//     }).catch(error => {
//       message.value = error.response?.data?.error || error.message || error
//     })
// }
</script>

<style lang="scss" scoped>

</style>
