<template>
  <div class="slideshow" :class="aspectClass">
    <div id="pixi-container" class="slideshow-container" :class="aspectClass">
      <canvas class="slideshow-canvas" id="slideshow-canvas" ref="slideshowCanvas"></canvas>
    </div>

    <div class="position-absolute" style="right:0;top:0;">
      <div v-if="recorderTimer" class="bg-danger text-white p-2 rounded" @click="stopRecording" >
        <i class="bi bi-circle-fill mr-2 recording"></i>
        RECORDING
      </div>
    </div>


  </div>

</template>

<script setup>
/* global PIXI */

import { gsap } from "gsap"

import {computed, defineProps, onMounted, onBeforeUnmount, nextTick, ref} from "vue" // useTemplateRef, ref,
const slideshowCanvas = ref(null)
const recorderTimer = ref(null)

let stream, recorder

const props = defineProps({
  items: {
    type: Array,
    required: true,
    default: () => []
  },
  holdDuration: {
    type: Number,
    default: 6000
  },
  fadeDuration: {
    type: Number,
    default: 500
  },
  transition: {
    type: String,
    default: 'crossfade'
  },
  randomOrder: {
    type: Boolean,
    default: false
  },
  aspectRatio: {
    type: Number,
    default: 1.0
  },
  showTitle: {
    type: Boolean,
    default: true
  },
  showPrice: {
    type: Boolean,
    default: true
  },
  showSold: {
    type: Boolean,
    default: true
  },
  bgColor: {
    type: String,
    default: '#DE3249'
  }
})

const slides = computed(() => props.items.map(item => ({
    title: item.title,
    sale_price: item.sale_price,
    photo: `https://i.ebayimg.com/images/g/${item.photo_url.match(/z\/([a-zA-Z0-9-~_]+)\/\$/)?.[1]}/s-l1600.jpg`,
    date: new Date(item.create_date).toLocaleDateString(),
    is_sold: item.is_sold > 0,
  })).sort(() => props.randomOrder ? Math.floor(Math.random()*3)-2 : 0)
)

const aspectClass = computed(() => {
  if (props.aspectRatio < 1) return 'landscape'
  if (props.aspectRatio > 1) return 'portrait'
  return 'square'
})

async function loadScript(src) {
  return new Promise((resolve, reject) => {
    const s = document.createElement('script');
    s.src = src;
    s.onload = resolve;  // Resolve when script is loaded
    s.onerror = reject;  // Reject if loading fails
    document.head.appendChild(s);
  });
}

let app = null;
let timeline = null;


function stopRecording() {
  if (recorderTimer.value) {
    clearTimeout(recorderTimer.value);
    recorder.stop()
  }
}

onMounted(async () => {
  await nextTick()
  await loadScript("https://cdn.jsdelivr.net/npm/pixi.js@7.2.4/dist/pixi.min.js");

  const canvas = slideshowCanvas.value
  const size = {x:1280, y:Math.floor(1280 * props.aspectRatio)}
  const padding = Math.floor(size.x / 25)

  app = new PIXI.Application({ width: size.x, height: size.y, view: canvas, backgroundAlpha: 0 });
  //document.getElementById('pixi-container').appendChild(app.view);

  //const scaleFactor = size.x / 1600
  const fadeD = props.fadeDuration / 1000
  const holdD = props.holdDuration / 1000
  const ease = 'elastic.out(0.6, 0.4)'
  // const ease = holdD ? 'elastic.out(0.6, 0.4)': 'power4.inOut' // : 'linear'
  const gsapParams = [];

  for(let i = 0; i < slides.value.length; i++) {
    const slide = new PIXI.Container();
    slide.width=size.x
    slide.height=size.y
    app.stage.addChild(slide);

    var graphics = new PIXI.Graphics();
    graphics.beginFill(props.bgColor);
    graphics.drawRect(-0, 0, size.x, size.y);
    graphics.endFill();
    slide.addChild(graphics)

    const sprite = await PIXI.Sprite.from(slides.value[i].photo)
    sprite.width = Math.min(size.x, size.y)
    sprite.height = Math.min(size.x, size.y)
    sprite.position.x = aspectClass.value === 'landscape' ? 0 : (slide.width / 2) - (sprite.width / 2);
    sprite.position.y = (slide.height / 2) - (sprite.height / 2);
    slide.addChild(sprite);

    const text = new PIXI.Text(slides.value[i].title, {
      fontFamily: 'Arial Black',
      fontSize: Math.floor(padding * (Math.abs(props.aspectRatio - 0.8) + 0.6)),
      //lineHeight: Math.floor(padding * 1.5 * props.aspectRatio ),
      fill: 0xffffff,
      wordWrap: true,
      wordWrapWidth: (aspectClass.value === 'landscape' ? size.x - size.y : size.x) - padding * 2,
      align: 'left',
      lineJoin: 'round',
      miterLimit: 3,
      padding: Math.floor(padding / 2),
      dropShadow: true,
      dropShadowColor: 'black',
      dropShadowBlur: Math.floor(padding / 5),
      stroke: 0x000000,
      strokeThickness: Math.floor(padding / 6),
    });

    text.x = (aspectClass.value === 'landscape' ? size.y : 0 ) + padding
    text.y = padding - Math.floor(padding / 3)


    if (props.showTitle) slide.addChild(text);

    if (props.showSold && slides.value[i].is_sold) {
      const sold = new PIXI.Text('SOLD', {
        fontFamily: 'Arial Black',
        fontSize: padding * 4,
        fill: 0xff0000,
        align: 'center',
        miterLimit: 2,
        padding: Math.floor(padding),
        dropShadow: true,
        dropShadowColor: 'black',
        dropShadowBlur: Math.floor(padding),
        stroke: 0xffffff,
        strokeThickness: Math.floor(padding / 3),
      })
      sold.x = size.x/2;
      sold.y = size.y/2;
      //sold.alpha = 0.9;
      sold.anchor.set(0.5, 0.5);
      sold.rotation = Math.PI / -8;
      slide.addChild(sold);
    }

    const price = new PIXI.Text(`$${parseFloat(slides.value[i].sale_price).toFixed(2)}`, {
      fontFamily: 'Lego',
      fontSize: Math.floor(padding * 3 * (Math.abs(props.aspectRatio - 0.8) + 0.8)),
      fill: 0xffff00,
      wordWrap: true,
      wordWrapWidth: size.x - padding * 2,
      align: aspectClass.value === 'portrait' ? 'left' : 'right',
      lineJoin: 'miter',
      miterLimit: 2,
      padding: padding,
      dropShadow: true,
      dropShadowColor: 'black',
      dropShadowBlur: Math.floor(padding / 3),
      stroke: 0x000000,
      strokeThickness: Math.floor(padding / 3),
    });

    if (aspectClass.value === 'portrait') {
      price.anchor.set(0,1)
      price.x = padding;
      price.y = size.y - (padding * 2);
    } else {
      price.anchor.set(1,1)
      price.x = size.x - padding;
      price.y = size.y - (padding / 3);
    }

    if (props.showPrice) slide.addChild(price);

    let alpha = [1,1,1]
    let x = [0,0,0]
    let y = [0,0,0]

    switch(props.transition) {
      case 'crossfade':
        alpha = [0,1,1]
        break;
      case 'drop':
        y = [-size.y,0,size.y]
        break;
      case 'slide':
        x = [size.x,0,-size.x]
        break;
      default:
        x[0] = (Math.floor(Math.random() * 2) ? 1 : -1) * size.x
        y[0] = (Math.floor(Math.random() * 2) ? 1 : -1) * size.y
        //alpha[0] = 0 //Math.floor(Math.random() * 2)
        // x[0] = (Math.floor(Math.random() * 3) - 1) * size.x
        // y[0] = (Math.floor(Math.random() * 3) - 1) * size.y
        //alpha[0] = x[0] || y[0] ? 1 : 0
        break;
    }

    slide.alpha = alpha[0];
    slide.x = x[0];
    slide.y = y[0];

    gsapParams.push([slide, {alpha: alpha[1], x:x[1], y:y[1], ease, duration: fadeD}, (holdD + fadeD) * i])
    //gsapParams.push([slide, {alpha: alpha[2], x:x[2], y:y[2], ease, duration: fadeD}, (holdD + fadeD) * (i + 1)])
  }

  await new Promise(resolve => setTimeout(resolve, props.holdDuration + props.fadeDuration))
  //gsap.ticker.fps(30);
  timeline = gsap.timeline()
  gsapParams.forEach(([slide, params, hold]) => timeline.to(slide, params, hold))

  // screen recorder
  stream = canvas.captureStream(30); // 30 fps
  recorder = new MediaRecorder(stream, { mimeType: 'video/webm' });

  const recordedChunks = [];
  recorder.ondataavailable = (event) => {
    if (event.data.size > 0) recordedChunks.push(event.data);
  };

  recorder.onstop = async () => {
    clearTimeout(recorderTimer.value);
    recorderTimer.value = null;

    const blob = new Blob(recordedChunks, { type: 'video/webm' });
    //await convertWebMtoMP4(blob);
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = `${new Date().toDateString()}-slideshow-recording.webm`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  };

  recorder.start()
  recorderTimer.value = setTimeout(() => {recorder.stop()}, (slides.value.length * (props.holdDuration + props.fadeDuration)));
  console.log('stop recording in ' + slides.value.length * (props.holdDuration + props.fadeDuration) + 'ms')
})

onBeforeUnmount(() => {
  if (recorder) {
    recorder.stop()
    recorder = null
    stream = null
  }

  if (timeline) {
    timeline.kill()
  }

  // Destroy PIXI app
  if (app) {
    app.destroy(true, { children: true, texture: true, baseTexture: true });
    app = null;
  }
})

</script>

<style lang="scss" scoped>
@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke*-1;
  @for $i from $from through $stroke {
    @for $j from $from through $stroke {
      $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
    }
  }
  $shadow: append($shadow, 10px 10px 10px rgba(0,0,0,0.7), comma);
  @return $shadow;
}
.slideshow {
  width: clamp(400px, 100%, 89vh);
  margin: 0 auto;
  &.portrait { width: clamp(400px, 100%, 50vh) }
  &.landscape { width: clamp(400px, 150vh, 100%) }
  .slideshow-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    border: 1px solid #eee;
    .slideshow-canvas {
      position: absolute;
      top:0; left:0;
      width: 100% !important;
      height: 100% !important;
    }
    &.square {
      padding-top: 100%;
    }
    &.portrait {
      padding-top: 177.78%
    }
    &.landscape {
      padding-top: 56.25%;
    }
  }
}
</style>
