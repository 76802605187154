<template>
  <div>
    <transition mode="out-in">
      <loading-spinner v-if="loading" />
      <div v-else>
        <div class="mb-2">
          <strong>Transactions</strong>
        </div>
        <div v-for="(transaction, idx) in transactions" :key="idx" class="row border-bottom small">
          <div class="col-4 small">{{ (new Date(transaction.transaction_date)).toLocaleDateString() }}</div>
          <div class="col-5 small fw-bold">{{ $underscoreToWords(transaction.feeType || transaction.transaction_type) }}</div>
          <div class="col-3 text-end"><money-block :dollar="transaction.transaction_amount" /></div>
        </div>

        <div v-if="transactions.length < 3" class="row border-bottom small">
          <div class="col-4 small">-</div>
          <div class="col-5 small fw-bold">-</div>
          <div class="col-3 text-end">-</div>
        </div>

        <div class="row border-bottom lead">
          <div class="col-4 small"></div>
          <div class="col-5 small fw-bold">NET</div>
          <div class="col-3 text-end"><money-block :dollar="net" /></div>
        </div>

      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, defineProps, computed } from "vue"
import { useStore } from "vuex"
import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";
import MoneyBlock from "@/components/Utils/MoneyBlock.vue";

const store = useStore()
const transactions = ref([])
const loading = ref(false)
const props = defineProps(['orderId'])
const net = computed(() => transactions.value.reduce((acc, cur) => acc + parseFloat(cur.transaction_amount), 0.0))

getOrderTransactions()

function getOrderTransactions() {
  store.dispatch("eshop/getOrderTransactions", { orderId: props.orderId })
      .then(data => {
        transactions.value = data
        loading.value = false
      })
}
</script>

<style lang="scss" scoped>

</style>
