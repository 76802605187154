<template>
  <div>
    <h1>Orders</h1>
    <nav class="nav nav-pills nav-fill mb-3">
        <router-link :to="{name:'Orders'}" class="nav-link" :class="{active:$route.name === 'Orders'}">All Orders</router-link>
        <router-link :to="{name:'TopOrders'}" class="nav-link" :class="{active:$route.name === 'TopOrders'}">Weekly Summaries</router-link>
    </nav>
    <router-view />
  </div>
</template>
<!--<order-list />-->
<!--<script setup>-->
<!--import OrderList from "@/components/OrderList.vue";-->
<!--</script>-->
<script setup lang="ts">
</script>
