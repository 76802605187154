<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex justify-content-center align-items-center">
        <span class="small text-muted me-2">{{ selectedItems.length }}</span>
        <div class="pe-2" @click="emit('onClear')" role="button">
          <i class="bi bi-ban" title="clear selected"></i>
        </div>
        <div class="pe-2" @click="emit('onVisible')" role="button">
          <i class="bi bi-eye" title="select visible"></i>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column flex-md-row">
        <select v-model="fadeDuration" class="form-select form-select-sm mx-md-1 my-1 my-md-0">
          <option v-for="option in [
              {label:'none (0)',value:1},
              {label:'short (.3s)', value:300},
              {label:'medium (.5s)', value:500},
              {label:'long (1s)',value:1000},
              {label:'super long (3s)',value:3000}
            ]" :value="option.value" :key="option.value">
            {{option.label}}
          </option>
        </select>
        <select v-model="holdDuration" class="form-select form-select-sm mx-md-1 my-1 my-md-0">
          <option v-for="option in [
                {label:'no delay',value:0},
                {label:'1/2 s',value:500},
                {label:'1 s',value:1000},
                {label:'3 s',value:3000},
                {label:'6 s', value:6000}
                ]" :value="option.value" :key="option.value">
            {{option.label}}
          </option>
        </select>
        <select v-model="transition" class="form-select form-select-sm mx-md-1 my-1 my-md-0">
          <option v-for="option in [
                {label:'Crossfade',value:'crossfade'},
                {label:'Slide', value:'slide'},
                {label: 'Drop', value:'drop'},
                {label: 'Random', value:'random'}
                ]" :value="option.value" :key="option.value">
            {{option.label}}
          </option>
        </select>
        <select v-model="aspectRatio" class="form-select form-select-sm mx-md-1 my-1 my-md-0">
          <option v-for="option in [
                {label:'Square',value:1.0},
                {label:'Portrait', value:16/9},
                {label:'Landscape', value:9/16},
                ]" :value="option.value" :key="option.value">
            {{option.label}}
          </option>
        </select>

        <input type="text" class="form-control form-control-sm mx-md-1 my-1 my-md-0" v-model="bgColor">

        <div class="small text-nowrap mx-md-1 my-1 my-md-0" role="button" @click="randomOrder=!randomOrder">
          <i class="bi" :class="{'bi-circle': !randomOrder, 'bi-check-circle': randomOrder}"></i> Random Order
        </div>

        <div class="small text-nowrap mx-md-1 my-1 my-md-0" role="button" @click="showTitle=!showTitle">
          <i class="bi" :class="{'bi-circle': !showTitle, 'bi-check-circle': showTitle}"></i> Title
        </div>

        <div class="small text-nowrap mx-md-1 my-1 my-md-0" role="button" @click="showPrice=!showPrice">
          <i class="bi" :class="{'bi-circle': !showPrice, 'bi-check-circle': showPrice}"></i> Price
        </div>

        <div class="small text-nowrap mx-md-1 my-1 my-md-0" role="button" @click="showSold=!showSold">
          <i class="bi" :class="{'bi-circle': !showSold, 'bi-check-circle': showSold}"></i> Sold
        </div>

        <button :disabled="disabled" type="button" class="btn btn-sm btn-primary mx-md-1 my-1 my-md-0" @click="slideShow=true">Slideshow</button>
      </div>
    </div>

    <app-modal :modal-in="slideShow" @close="slideShow=false">
      <template #header>Inventory Slideshow</template>
      <div v-if="slideShow">
        <inventory-slideshow
            :items="selectedItems"
            :fade-duration="fadeDuration"
            :hold-duration="holdDuration"
            :random-order="randomOrder"
            :aspect-ratio="aspectRatio"
            :show-title="showTitle"
            :show-price="showPrice"
            :show-sold="showSold"
            :bg-color="bgColor"
            :transition="transition"/>
      </div>
    </app-modal>
  </div>
</template>

<script setup>

import {computed, ref, defineProps, defineEmits} from "vue";
import InventorySlideshow from "@/components/InventorySlideshow.vue";
import AppModal from "@/components/Utils/AppModal.vue";

const emit = defineEmits(["onClear"]);
const props = defineProps({
  selectedItems: {
    type: Array,
    default: () => []
  }
})

const slideShow = ref(false)
const holdDuration = ref(1000)
const fadeDuration = ref(500)
const transition = ref('crossfade')
const randomOrder = ref(false)
const aspectRatio = ref(1.0)
const showTitle = ref(true)
const showPrice = ref(true)
const showSold = ref(true)
const bgColor = ref('#cc0000')
const disabled = computed(() => props.selectedItems.length < 2)

</script>
