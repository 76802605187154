<template>
  <div>
    <transition mode="out-in">
      <div v-if="fetching" class="h-page d-flex align-items-center justify-content-center">
        <loading-spinner />
      </div>
      <div v-else-if="error" class="alert alert-warning">
        {{ error }}
      </div>
      <div v-else class="h-page">
        <div class="p-2 text-center text-white fw-bold mb-3"
        :class="{'bg-success': order.orderFulfillmentStatus==='FULFILLED', 'bg-warning': order.orderFulfillmentStatus!=='FULFILLED'}">
          <span class="me-2">{{ order.paymentSummary.payments[0].paymentStatus }}</span>
          <span class="me-3">
            {{ new Date(order.paymentSummary.payments[0].paymentDate).toLocaleDateString() }}
          </span>
          <span class="bi bi-truck me-2"></span>
          SHIPPING: {{ order.orderFulfillmentStatus }}
        </div>

        <div class="row mb-3">
          <div class="col-12 col-md-6 mb-3">

            <ebay-item
                v-for="line in order.lineItems"
                :key="line.lineItemId"
                :line="line"
                :discreet="discreet"
                class="mb-3"
            />

          </div>

          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <div class="card mb-3">
              <div class="card-body">
                <div v-if="!discreet" class="mb-3">
                  <h3>Buyer</h3>
                  <div>{{ order.buyer?.username }}</div>
                  <address-block :address="order.buyer?.buyerRegistrationAddress" class="d-none" />
                </div>

                <div v-for="(fulfillment, idx) in order.fulfillmentStartInstructions || []" :key="idx">
                  <div v-if="fulfillment.shippingStep && fulfillment.shippingStep.shipTo">
                    <h3>Ship To</h3>
                    <address-block :address="fulfillment.shippingStep?.shipTo" :discreet="discreet" />
                  </div>
                </div>

              </div>
            </div>



            <div class="card">
              <div class="card-body">
                <h2>Order Summary</h2>
                <div class="d-flex" v-for="key in Object.keys(order.pricingSummary)" :key="key">
                  <div class="me-3 fw-bold">{{ $humpToWords(key) }}</div>
                  <div class="ms-auto">${{ parseFloat(order.pricingSummary[key].value).toFixed(2) }}</div>
                </div>

                <hr>

                <div class="d-flex" v-if="order.totalFeeBasisAmount">
                  <div class="me-3 fw-bold">Fee Basis (w/ Taxes)</div>
                  <div class="ms-auto">${{ parseFloat(order.totalFeeBasisAmount.value).toFixed(2) }}</div>
                </div>

                <div class="d-flex" v-if="order.totalMarketplaceFee">
                  <div class="me-3 fw-bold">Seller Fees</div>
                  <div class="ms-auto text-danger">${{ -parseFloat(order.totalMarketplaceFee.value).toFixed(2) }}</div>
                </div>

                <hr>

                <div class="d-flex">
                  <div class="me-3 fw-bold">Total Collected</div>
                  <div class="ms-auto text-success">${{ parseFloat(order.paymentSummary?.totalDueSeller?.value).toFixed(2) }}</div>
                </div>

                <hr>

                <order-transactions :order-id="orderId" />

              </div>
            </div>


          </div>
        </div>


      </div>
    </transition>
  </div>
</template>

<script setup>
  import {ref, defineProps} from "vue";
  import {useStore} from "vuex";

  import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";
  import EbayItem from "@/components/ebay/EbayItem.vue";

  import AddressBlock from "@/components/AddressBlock.vue";
  import OrderTransactions from "@/components/OrderTransactions.vue";

  const props = defineProps(['orderId', 'discreet'])
  const store = useStore()
  const order = ref(null)
  const error = ref(null)
  const fetching = ref(false)

  getOrder()

  function getOrder() {
    if (!props.orderId) {
      error.value = 'No Order ID'
      return
    }
    fetching.value = true
    store.dispatch('eshop/getEbayOrder', { orderId: props.orderId})
        .then(res => {
          order.value = res
          fetching.value = false
        })
        .catch(err => {
          error.value = err
          fetching.value = false
        })
  }
</script>

<style lang="scss" scoped>
.right-column {width : 200px;}
</style>
