<template>
  <div>
    <transition mode="out-in">
      <loading-spinner v-if="fetching" />
      <div v-else>

        <inventory-slideshow-button class="mb-3"
                                    :selected-items="selectedItems"
                                    @on-visible="selectVisible"
                                    @on-clear="clearSelected" />

        <div class="mb-3 d-flex flex-row align-items-center">
          <input type="search" v-model="searchTerm" placeholder="Search Title" class="form-control">
          <button @click="gridView=!gridView" class="btn btn-light ms-3">
            <i class="bi" :class="{'bi-grid': !gridView, 'bi-list': gridView}"></i>
          </button>
        </div>
        <transition mode="out-in">
          <div v-if="gridView" class="border grid-wrapper" style="width:640px; height: 640px;">
            <div class="grid-item" v-for="inv in selectedItems.slice().sort(() => Math.floor(Math.random()*3)-2)" :key="inv.id" >docker
              <img :src="inv.photo_url" class="img-fluid" alt="item image">
            </div>
          </div>
          <div v-else>
          <inventory-item :item="inv"
             v-for="inv in filteredInventory"
             class="mb-3"
             :selected="selectedList.indexOf(inv.id) >= 0"
             @on-selected="(checked) => updateList(inv.id, checked)"
             :key="inv.id"/>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";
import InventoryItem from "@/components/InventoryItem.vue";
import InventorySlideshowButton from "@/components/InventorySlideshowButton.vue";
const store = useStore()

const fetching = ref(false)
const searchTerm = ref('')
const gridView = ref(false)
const inventory = computed(() => store.state.eshop.inventory)
const selectedList = ref(inventory.value.slice(0,20).map(i => i.id))
const selectedItems = computed(() => inventory.value.slice().filter(l => selectedList.value.indexOf(l.id) >= 0))

const filteredInventory = computed(() => {
  if (!searchTerm.value.length) { return inventory.value.slice(0,50) }
  return inventory.value
      .slice()
      .filter(l => {
        const searchText = searchTerm.value.toString().replace(/\W+/g, '').toLowerCase()
        return l.title.replace(/\W+/g, '').toLowerCase().indexOf(searchText) >= 0
      })
      .slice(0,50)
})

function clearSelected() {
  selectedList.value = [];
}

function selectVisible() {
  clearSelected()
  selectedList.value = filteredInventory.value.slice(0, 35).map(i => i.id)
}

function updateList(id, selected) {
  selectedList.value = selectedList.value.slice().filter(l => l !== id)
  if (selected) selectedList.value = [ ...selectedList.value, id ]
}

watch(fetching.value, (newVal) => {
  if (newVal) {
    fetching.value = false
  }
})

</script>

<style lang="scss" scoped>
.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  width: 640px;
  height: 640px;
  margin: 0 auto;
  gap: 0;
  overflow: hidden;
}
</style>
