<template>
  <div>
    <h2>Weekly Orders</h2>

    <static-alert :message="message" @on-hide="message=''" />
    <div class="border-top border-3 border-success border-bottom p-2 mb-3 d-flex align-items-center unmargin">
      <div class="me-auto lead d-flex align-items-center">
        <span class="me-2">{{ totalOrderCount }}</span>
        <i class="bi bi-receipt me-2"></i>
        <span><strong><money-block :dollar="totalOrderValue" /></strong></span>
      </div>

      <div class="mx-3 d-flex align-items-center">
        <esc-date-picker @on-date-change="onDateChange" />
      </div>
      <div class="ms-auto d-flex align-items-center">
        <div
            class="me-3 px-3 py-1 small"
            role="button"
            @click="pseudoFetch(() => topTen=!topTen)">
          <i class="bi me-1" :class="{'bi-check-circle':topTen, 'bi-circle':!topTen}"></i>
          <span>Top Ten</span>
        </div>
        <button type="button" class="btn btn-outline-primary btn-light rounded rounded-pill btn-sm" @click="startPresentation">
          <i class="bi bi-camera-reels"></i>
        </button>
      </div>

    </div>
    <div class="h-page">
      <transition mode="out-in">
        <div v-if="fetching" class="h-page d-flex justify-content-center align-items-center">
          <loading-spinner  />
        </div>
        <div v-else-if="pageList[currentOrder]" class="h-page striped">
          <div v-for="order in pageList" :key="order.oid" class="py-1 d-flex">
            <div class="me-3 small text-muted">
              <div>{{ new Date(order.trans_date).toLocaleString() }}</div>
            </div>
            <div class="me-auto">{{ order.oid }}</div>
            <div class="ms-auto small"><small>{{ order.types }}</small></div>
            <div class="ms-3">{{ order.net }}</div>
          </div>

          <app-modal :modal-in="presentationMode" @close="presentationMode=false">
            <div class="d-flex mb-3 align-items-center">
              <button class="btn btn-light btn-outline-primary me-auto" @click="nextOrder(-1)">
                <i class="bi bi-chevron-left" title="Previous Order"></i>
              </button>
              <h2 class="mx-3"># {{ currentOrder + 1 }}</h2>
              <button class="btn btn-light btn-outline-primary ms-auto" @click="nextOrder(1)">
                <i class="bi bi-chevron-right" title="Next Order"></i>
              </button>
            </div>
            <transition mode="out-in">
              <div class="container">
              <ebay-order :order-id="pageList[currentOrder].oid" :key="currentOrder" :discreet="true" />
              </div>
            </transition>
          </app-modal>

        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import {ref, computed, nextTick} from "vue"
  import { useStore } from "vuex"
  import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";
  import MoneyBlock from "@/components/Utils/MoneyBlock.vue";
  import StaticAlert from "@/components/Utils/StaticAlert.vue";
  import EbayOrder from "@/components/ebay/EbayOrder.vue";
  import AppModal from "@/components/Utils/AppModal.vue";
  import EscDatePicker from "@/components/Utils/EscDatePicker.vue";

  const store = useStore()
  const orderList = ref([])
  const fetching = ref(false)
  const message = ref('')
  const currentOrder = ref(0)
  const presentationMode = ref(false)
  const topTen = ref(false)

  const fDate = ref(new Date());
  const tDate = ref(new Date());
  const offset = new Date().getTimezoneOffset()

  const pageList = computed(() => {
    return orderList.value.slice()
        .filter(o => {
          const d = new Date(o.trans_date)
          d.setMinutes(d.getMinutes() - offset)
          return d < tDate.value && d >= fDate.value
        })
        .sort((a, b) => parseFloat(b.net) - parseFloat(a.net))
        .slice(0, topTen.value ? 10 : orderList.value.length)
  })

  const totalOrderCount = computed(() => pageList.value.length || 0)
  const totalOrderValue = computed(() => pageList.value.reduce((acc, item) => acc + parseFloat(item.net), 0.0))

  // pre load page
  getOrders()

  function getOrders() {
    fetching.value = true
    store.dispatch("eshop/getOrders")
        .then(orders => {
          orderList.value = orders
          currentOrder.value = 0; //orderList.value.length - 1
          fetching.value = false
          //setDateSpan()
        })
        .catch(error => {
          message.value = error.response?.data || error.message || error
          fetching.value = false
        })
  }

  function startPresentation() {
    currentOrder.value = 0
    presentationMode.value = true
  }

  function nextOrder(dir) {
    let newVal = currentOrder.value + dir
    if (newVal < 0) newVal = pageList.value.length - 1
    if (newVal >= pageList.value.length) newVal = 0
    currentOrder.value = newVal
  }

  function pseudoFetch(callback) {
    fetching.value = true
    if (typeof callback === 'function') callback()
    nextTick(() => fetching.value = false)
  }

  function onDateChange([f,t]){
    pseudoFetch(() => {
      fDate.value=f
      tDate.value=t
    })
  }
</script>

<style lang="scss" scoped>
  .striped > div:nth-of-type(2n+1) {background: rgba(0,0,0,0.05);}
</style>
