<template>
  <div>
    <div class="d-flex flex-row align-items-center mb-3">
      <h1>Inventory</h1>
      <a :href="website" class="btn btn-sm btn-link ms-auto">{{website}}</a>
    </div>

    <div class="card mb-3"><div class="card-body p-1">
      <item-feed />
    </div></div>

    <static-alert v-if="errors" :message="errors" @on-hide="errors=''" />

    <div v-if="loaded">
      <inventory-item-list />
    </div>


  </div>
</template>

<script setup>
import ItemFeed from "@/components/ItemFeed.vue";
import {computed, ref} from "vue";
import {useStore} from "vuex"
import StaticAlert from "@/components/Utils/StaticAlert.vue";
import InventoryItemList from "@/components/InventoryItemList.vue";
const store = useStore()
const website = computed(() => store.getters["eshop/config"]('WEBSITE_URL'))

const loaded = ref(false)

store.dispatch("eshop/getInventory").then(() => loaded.value=true)

</script>

<style lang="scss" scoped>

</style>
