<template>
  <span>
    <transition mode="out-in">
      <loading-spinner v-if="saving" :small="true" />
      <div v-else-if="message" class="fw-bold small">{{ message }}</div>
      <button v-else class="btn btn-sm btn-light" @click.stop="saveItem">
        <i class="bi-save2 me-2"></i> eBay Update
      </button>
    </transition>
  </span>
</template>

<script setup>
import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";
import { ref, defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const message = ref(null)
const saving = ref(false)
const props = defineProps({itemId: String})
const emits = defineEmits(['onUpdateItem'])

function queueMessage(msg) {
  message.value = msg
  setTimeout(() => message.value = null, 1500)
}

async function saveItem() {
  try {
    saving.value = true
    const result = await store.dispatch('eshop/updateInventoryItem', {id: props.itemId})
    queueMessage(result?.error?.sqlMessage || result?.error || `Updated ${result?.item?.ItemID}`)
    emits('onUpdateItem', result?.item)
    saving.value = false
  } catch (err) {
    queueMessage(err.message || err)
    saving.value = false
  }
}

</script>

<style lang="scss" scoped>

</style>
