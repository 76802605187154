<template v-if="input">
  <tr @click.stop="toggleDetails">
    <td>
      <span class="bi bi-enlarged text-white" :class="iconClass"
            :title="notes.transactionType || ''"></span>
    </td>
    <td class="text-muted small">{{ input.date }}</td>
    <td>
      <small>{{ input.description }}</small>
      <small class="d-none"><object-display :obj="input" :overflow="true" /></small>
    </td>
    <td class="text-end">
      <strong :class="{'text-success': input.anchor.direction === 'DEPOSIT' || input.anchor.direction === 'CREDIT', 'text-danger': input.anchor.direction === 'WITHDRAWAL'}">
        ${{ input.anchor.amount.toFixed(2) }}
      </strong>
    </td>
    <td class="text-end">
      <transition mode="out-in">
        <loading-spinner v-if="loadingB" :small="true" class="mx-2" />
        <span role="button" v-else-if="importedB" class="px-2 mx-1 text-success" @click.stop>&checkmark;</span>
        <span role="button" v-else class="d-none px-2 mx-1 rounded border border-info text-info bg-info-subtle" @click.stop="onAddTrans(input)">&plus;</span>
      </transition>

      <wave-accounting-btn :input="input" />
    </td>
  </tr>


    <app-modal :modal-in="show" @close="show=false">
      <template #header>Order Details {{ orderId }}</template>
      <div class="container">
        <ebay-order :order-id="orderId" :key="orderId" />
      </div>
    </app-modal>


</template>

<script setup>
  import { defineProps, ref, computed } from 'vue'
  import { useStore } from 'vuex'
  import LoadingSpinner from "@/components/Utils/LoadingSpinner.vue";
  import EbayOrder from "@/components/ebay/EbayOrder.vue";
  import ObjectDisplay from "@/components/Utils/ObjectDisplay.vue";
  import WaveAccountingBtn from "@/components/waveapps/WaveAccountingBtn.vue";
  import AppModal from "@/components/Utils/AppModal.vue";

  const show = ref(false)
  const loadingB = ref(false)
  const store = useStore()
  const importedB = ref(false)

  const props = defineProps({
    input: {
      type: Object,
      required: true,
      default: () => {}
    }
  })

  const notes = computed(() => JSON.parse(props.input.notes || '{}'))
  const config = computed(() => store.state.eshop.store.config)
  const orderId = computed(() => {
    if (notes.value.orderId) return notes.value.orderId
    if (notes.value.references) {
      const reference = notes.value.references.find(r => r.referenceType === "ORDER_ID")
      if (reference) return reference.referenceId
    }
    return null
  })

  const iconClass = computed(() => {
    switch (notes.value.transactionType) {
      case 'SHIPPING_LABEL':
        return 'bi-truck bg-info'
      case 'SALE':
        return 'bi-cart2 bg-success'
      case 'NON_SALE_CHARGE':
        return 'bi-badge-ad bg-secondary'
      case 'REFUND':
        return 'bi-arrow-return-left bg-warning'
      default:
        return 'bi-circle bg-black'
    }
  })

  function toggleDetails() {
    show.value = !show.value
  }

  function remoteToLocal(id) {
    const idKey = Object.keys(config.value).find(key => id === config.value[key])
    return config.value[idKey.replace(/^WAVE_/,'')]
  }

  function mapLocal(input) {
    const mapped = {};
    Object.keys(input).map(key => {
      if (Array.isArray(input[key])) {
        mapped[key] = input[key].map(mapLocal)
      } else if (key === 'accountId') {
        mapped[key] = remoteToLocal(input[key])
      } else if (typeof input[key] === 'object') {
        mapped[key] = mapLocal(input[key])
      } else {
        mapped[key] = input[key];
      }
    })
    return mapped
  }

  function onAddTrans(input) {
    loadingB.value = true
    const mapped = mapLocal(input)

    store.dispatch('eshop/addAccountTransaction', {input: mapped})
        .then(() => {
          loadingB.value = false
          importedB.value = true
        })
        .catch(e => {
          alert(e.response.data.error || e.message || JSON.stringify(e))
          loadingB.value = false
        })
  }
</script>
