<template>
  <div ref="imageContainer" class="image-container">
    <img
        :src="isIntersecting ? imageSrc : placeholder"
        alt="Lazy loaded image"
        class="img-fluid"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineProps } from 'vue';

// Define the props
defineProps({
  imageSrc: {
    type: String,
    required: true,
  },
});

const placeholder = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

const imageContainer = ref(null);
const isIntersecting = ref(false);

let observer = null;

// Lazy load logic
const handleIntersect = (entries) => {
  if (entries[0].isIntersecting) {
    isIntersecting.value = true;
    observer.disconnect(); // Stop observing once loaded
  }
};

onMounted(() => {
  observer = new IntersectionObserver(handleIntersect, {
    root: null, // Observes viewport by default
    threshold: 0.1, // Trigger when 10% of the element is visible
  });

  if (imageContainer.value) {
    observer.observe(imageContainer.value);
  }
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
  }
});
</script>

<style>
.image-container {
  min-width: 32px;
  max-width: 100%;
  height: auto;
  min-height: 32px; /* Placeholder height */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0; /* Placeholder background color */
}
</style>
