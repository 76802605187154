<template>
  <tr v-if="order" @click="show=true;visited=true;" role="button" :class="{'table-success':visited}">
    <td class="small">{{ (new Date(order.trans_date)).toLocaleString() }}</td>
    <td class="fw-bold small">{{ order.oid }}</td>
    <td>
      <div class="card shadow shadow-sm">
        <div class="card-body p-1">
          <transition mode="out-in">
            <div v-if="open">
              <div v-for="(type, idx) in order.types.split(',')" :key="idx" class="small d-flex">
                <div class="me-2">{{ $underscoreToWords(type.split('|')[0]) }}</div>
                <div class="ms-auto">{{ type.split('|')[1] }}</div>
              </div>
            </div>
          </transition>
          <div class="d-flex mt-1 pt-1 border-top">
            <div class="me-2"><span class="caret" :class="{'caret-right': !open, 'caret-down' : open}"></span></div>
            <div class="ms-auto"><money-block :dollar="order.net" class="fw-bold"/></div>
          </div>
        </div>
      </div>
    </td>
    <app-modal :modal-in="show" @close="show=false">
      <template #header>Order Details {{ order.oid }}</template>
      <div class="container">
        <ebay-order :order-id="order.oid" />
      </div>
    </app-modal>
  </tr>
</template>

<script setup>
import { ref, defineProps } from "vue"
import MoneyBlock from "@/components/Utils/MoneyBlock.vue";
import AppModal from "@/components/Utils/AppModal.vue";
import EbayOrder from "@/components/ebay/EbayOrder.vue";

const show = ref(false)
const open = ref(true)
const visited = ref(false)
defineProps(['order'])
</script>

<style lang="scss" scoped>
.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}
</style>
