<template>
  <div class="text-center">
    <div class="d-flex align-items-center">
      <input type="date" disabled class="form-control form-control-sm" :value="fromDate">
      <input type="date" disabled v-model="toDate" class="form-control form-control-sm mx-1">
      <select v-model="week" @change="selectWeek" class="form-select form-select-sm">
        <option v-for="w in 52" :key="w" :value="w">Week {{ w }}</option>
      </select>
    </div>

  </div>
</template>

<script setup>
import {ref, defineEmits, watch, onMounted} from "vue";

const dateSpan = ref(7)
const fromDate = ref(new Date().toISOString().slice(0, 10))
const toDate = ref(new Date().toISOString().slice(0, 10))
const offset = new Date().getTimezoneOffset()
const emits = defineEmits(["onDateChange"]);

const date = new Date()
const startOfYear = new Date(date.getFullYear(), 0, 1);
startOfYear.setDate(startOfYear.getDate() + (startOfYear.getDay() % 7));
const currentWeek = Math.round((date - startOfYear) / (7 * 24 * 3600 * 1000)) + 1;
const week = ref(currentWeek - 1)

watch(dateSpan, updateDate)
watch(toDate, updateDate)

onMounted(selectWeek)

function selectWeek() {
  let date = new Date()
  date = new Date(date.getFullYear(), 0, 1)
  date.setDate(date.getDate() + week.value * 7 - 1)
  toDate.value = date.toISOString().slice(0, 10)
}

function updateDate() {
  const newDate = new Date(toDate.value)
  newDate.setDate(newDate.getDate() - (dateSpan.value - 1))
  fromDate.value = newDate.toISOString().slice(0, 10)

  const fDate = new Date(fromDate.value)
  const tDate = new Date(toDate.value)
  fDate.setDate(fDate.getDate() + 1)
  fDate.setHours(0)
  fDate.setMinutes(-offset)
  //fDate.setMinutes(fDate.getMinutes() + offset) // convert back to utc

  tDate.setDate(tDate.getDate() + 1)
  tDate.setHours(23)
  tDate.setMinutes(59 - offset)
  tDate.setSeconds(59)

  emits('onDateChange', [fDate, tDate])
}
</script>

<style lang="scss" scoped>

</style>
